"use client";

import React from "react";

import { useAccount } from "@/lib/contexts/account-context";

import { AuthenticationModal } from "./AuthenticationModal";
import { PasswordResetModal } from "./PasswordResetModal";

export default function AuthenticationModals() {
  const { customer } = useAccount();
  if (customer) {
    return null;
  }
  return (
    <>
      <AuthenticationModal />
      <PasswordResetModal />
    </>
  );
}
