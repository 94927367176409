import React, { useMemo } from "react";

type AlertType = "success" | "error" | "info" | "warning";

export interface AlertProps {
  children: React.ReactNode;
  type: AlertType;
  className?: string;
}

// Tailwind strips dynamic classes from the production build
// @see https://stackoverflow.com/a/72202973/740183
type AlertTextStyle = "text-green-800" | "text-red-800" | "text-blue-800" | "text-yellow-800";
type AlertBoxStyle = "bg-green-50" | "bg-red-50" | "bg-blue-50" | "bg-yellow-50";
type AlertBorderStyle = "border-green-200" | "border-red-200" | "border-blue-200" | "border-yellow-200";

export default function Alert(props: AlertProps) {
  const alertTextStyle = useMemo((): AlertTextStyle => {
    switch (props.type) {
      case "success":
        return "text-green-800";
      case "error":
        return "text-red-800";
      case "info":
        return "text-blue-800";
      case "warning":
        return "text-yellow-800";
      default:
        throw new Error(`Invalid alert type: ${props.type}`);
    }
  }, [props.type]);

  const alertBoxStyle = useMemo((): AlertBoxStyle => {
    switch (props.type) {
      case "success":
        return "bg-green-50";
      case "error":
        return "bg-red-50";
      case "info":
        return "bg-blue-50";
      case "warning":
        return "bg-yellow-50";
      default:
        throw new Error(`Invalid alert type: ${props.type}`);
    }
  }, [props.type]);

  const alertBorderStyle = useMemo((): AlertBorderStyle => {
    switch (props.type) {
      case "success":
        return "border-green-200";
      case "error":
        return "border-red-200";
      case "info":
        return "border-blue-200";
      case "warning":
        return "border-yellow-200";
      default:
        throw new Error(`Invalid alert type: ${props.type}`);
    }
  }, [props.type]);

  return (
    <div className={`border border-solid rounded p-4 mb-4 ${alertBorderStyle} ${alertTextStyle} ${alertBoxStyle} ${props.className}`}>{props.children}</div>
  );
}
