import { ErrorMessage } from "@hookform/error-message";
import { Cart } from "@medusajs/medusa";
import clsx from "clsx";
import { useCart, useUpdateCart } from "medusa-react";
import React, { useEffect, useImperativeHandle } from "react";
import { get, useForm } from "react-hook-form";

import { ArrowDropRightIcon } from "../../Icon/Arrow/ArrowDrop/ArrowDropRightIcon";
import { PaybackIcon } from "../../Icon/Provider/PaybackIcon";

type PaybackFormValues = {
  bonus_card: string;
};
export interface PaybackCartType extends Cart {
  bonus_points_total?: number;
}

type InputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, "placeholder"> & {
  label: string;
  errors?: Record<string, unknown>;
  touched?: Record<string, unknown>;
  name: string;
};

export const PaybackInput = React.forwardRef<HTMLInputElement, InputProps>(({ type, name, label, errors, touched, required, className, ...props }, ref) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => inputRef.current!);

  const hasError = get(errors, name);

  return (
    <div>
      <div className="relative z-0 w-full text-base-regular flex">
        <input
          type={type}
          name={name}
          placeholder={label}
          className={clsx(
            "focus:outline-none p-2 font-semibold bg-gray-100 text-gray-700 placeholder-payback placeholder:font-semibold",
            {
              "bg-gray-100 text-red-500 placeholder-red-500": hasError,
            },
            className,
          )}
          {...props}
          ref={inputRef}
        />
        <button type="submit" className="bg-payback text-white p-2" disabled={props.disabled}>
          <ArrowDropRightIcon className="w-6 h-5" fill="currentcolor" />
        </button>
      </div>
      {hasError && (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => {
            return (
              <div className="text-red-500 font-semibold">
                <span>{message}</span>
              </div>
            );
          }}
        />
      )}
    </div>
  );
});
PaybackInput.displayName = "PaybackInput";

export const CartPayback = ({ cart }: { cart: PaybackCartType }) => {
  const { mutate, isLoading } = useUpdateCart(cart.id);
  const { setCart } = useCart();
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
    reset,
    setValue,
  } = useForm<PaybackFormValues>({
    mode: "onSubmit",
  });
  useEffect(() => {
    if (cart?.id) {
      setValue("bonus_card", (cart.metadata?.bonus_card as string | undefined) || "");
    }
  }, [cart?.id, cart?.metadata?.bonus_card, reset, setValue]);
  const onApply = (data: PaybackFormValues) => {
    mutate(
      {
        context: { bonus_card: data.bonus_card },
      },
      {
        onSuccess: ({ cart }) => {
          setCart(cart);
        },
        onError: () => {
          setError(
            "bonus_card",
            {
              message: "Ungültige PAYBACK Kartennummer",
            },
            {
              shouldFocus: true,
            },
          );
        },
      },
    );
  };
  return (
    <div className="mt-10">
      <p className="text-payback font-bold">Sammeln Sie PAYBACK Punkte?</p>
      <form onSubmit={handleSubmit(onApply)} className="w-full my-4">
        <PaybackInput className="w-full" label="PAYBACK Kartennummer eingeben" {...register("bonus_card")} errors={errors} disabled={isLoading} />
      </form>
      {cart.bonus_points_total ? (
        <div className="flex gap-4">
          <PaybackIcon width={20} height={20} />
          <p className="text-payback font-bold">Sie können für diesen Einkauf {cart.bonus_points_total.toString()} PAYBACK Punkte erhalten.</p>
        </div>
      ) : null}
    </div>
  );
};
