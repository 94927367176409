import { Cart } from "@medusajs/medusa";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import Skeleton from "react-loading-skeleton";

import { medusaClient } from "@/lib/config";
import { AmazonPayExpressButton } from "@/modules/checkout/components/Payments/payment-amazonpay";
import { PaypalExpressButton } from "@/modules/checkout/components/Payments/payment-paypal";

export const useCartExpressCheckout = (cartId: string) => {
  return useQuery(["cart", cartId], () => medusaClient.client.request("GET", `store/carts/${cartId}/express`));
};

export const CartExpressCheckoutButtons = ({ data }: { data: any }) => {
  return (
    <>
      {data && <AmazonPayExpressButton data={data} className="mb-2" />}
      <PaypalExpressButton />
    </>
  );
};

const CartExpressSkeleton = () => <Skeleton count={2} className="h-6 mb-2" />;

const CartExpressCheckoutWithData = ({ cart }: { cart: Cart }) => {
  // Wenn Amazon Pay nicht aktiviert ist, schlägt dieser Request fehl, was aber nicht weiter schlimm ist. Es hat dasselbe
  // Verhalten, als wenn keine Daten zurückgegeben werden.
  const { data, isLoading } = useCartExpressCheckout(cart.id);
  return isLoading ? <CartExpressSkeleton /> : <CartExpressCheckoutButtons data={data} />;
};

export const CartExpressCheckout = ({ cart }: { cart: Cart }) => {
  return (
    <div className="mt-10">
      <p className="text-center font-bold mb-4">Express checkout</p>
      {cart?.id ? <CartExpressCheckoutWithData cart={cart} /> : <CartExpressSkeleton />}
    </div>
  );
};
