"use client";

import { Region } from "@medusajs/medusa";
import { Cart as CartType } from "@medusajs/medusa/dist/models/cart";
import { useCart } from "medusa-react";
import React, { useEffect } from "react";

import { CartIcon } from "@/components/Icon/CartIcon";
import { Flyout } from "@/components/Modal/Flyout";
import { CartTotals, Products } from "@/components/pages/checkout/CartComponents";
import { CartDiscount } from "@/components/pages/checkout/CartDiscount";
import { CartPayback } from "@/components/pages/checkout/CartPayback";
import { PaymentProvider } from "@/components/Provider/PaymentProvider";
import { useLoading } from "@/lib/contexts/loading-context";
import { CART_MODAL, useHashToModal, useSideModal } from "@/lib/contexts/sidemodal-context";
import { useProductPriceFormat } from "@/lib/hooks/use-product-price";
import { checkoutStepEvent, CheckoutStepEventInterface } from "@/lib/util/gtm";
import Button from "@/modules/common/components/button";

import { CartExpressCheckout } from "../../pages/checkout/CartExpressCheckout";

export const CartSidebar = () => {
  const { modal, close } = useSideModal();
  const isOpen = modal.name === CART_MODAL && modal.isShow;
  const { cart } = useCart();
  const price = useProductPriceFormat({ region: cart?.region as Region });
  const [isLoading, setLoading] = useLoading("cart/sidebar", true, 0.2);

  const sale = 0;

  useHashToModal("cart", CART_MODAL);
  useEffect(() => {
    if (cart && cart.id) {
      setLoading(false);
    }
  }, [cart, setLoading]);

  useEffect(() => {
    if (isOpen && !isLoading && cart?.id) {
      checkoutStepEvent({ step: 0, cart } as CheckoutStepEventInterface);
    }
  }, [isOpen, isLoading, cart]);

  const empty = !cart?.items?.length && !cart?.total;

  return (
    <div id="cart">
      <Flyout
        right
        open={isOpen}
        onClose={close}
        button={
          !empty && (
            <Button href="/checkout" variant="active" className="py-2 px-4 uppercase w-full text-center" onClick={close}>
              Zur Kasse
            </Button>
          )
        }
      >
        <div className="mb-14">
          <div className="text-center">
            <div className="float-left mt-[0.1rem] ml-4">
              <CartIcon width={30} height={30} />
            </div>
            <div className="text-2xl uppercase mr-[2rem]">Warenkorb</div>
          </div>

          <hr className="my-5 h-0.5 border-t-0 bg-black opacity-100" />

          <div className="mt-10">
            <Products isLoading={isLoading} cart={cart as CartType} sale={sale} price={price} setLoading={setLoading} onNavigate={close} />
          </div>

          <CartTotals cart={cart as CartType} onlySubtotal={!cart?.shipping_address} />

          {isOpen && !empty && <CartExpressCheckout cart={cart as CartType} />}

          <CartPayback cart={cart as CartType} />

          <CartDiscount cart={cart as CartType} isLoading={isLoading} />

          <div className="mt-10">
            <PaymentProvider />
          </div>
        </div>
      </Flyout>
    </div>
  );
};
