import { PricedProduct } from "@medusajs/medusa/dist/types/pricing";
import clsx from "clsx";
import { useCart } from "medusa-react";
import React, { useCallback, useEffect, useState } from "react";

import { ArrowDropRightIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropRightIcon";
import ProductCard from "@/components/Product/ProductCard";
import { LastSearch } from "@/components/Search/last-search";
import { TermSuggests } from "@/components/Search/TermSuggests";
import ProductCardSkeleton from "@/components/Skeleton/Product/Card";
import medusaRequest from "@/data/medusa-fetch";
import { useSearch } from "@/lib/contexts/search-context";
import { useSideModal } from "@/lib/contexts/sidemodal-context";
import { productCardClickEvent, ProductEventData, productListViewEvent } from "@/lib/util/gtm";
import transformProductPreview from "@/lib/util/transform-product-preview";
import { ProductPreviewType } from "@/types/global";

export const SearchComponent = ({ searchValue, onSearch }: { searchValue: string; onSearch: (term: string, originalQuery?: string) => void }) => {
  const { close } = useSideModal();
  const { cart } = useCart();
  const search = useSearch();
  const [previews, setPreviews] = useState<undefined | ProductPreviewType[]>();
  const [gtmEventSent, setGtmEventSent] = useState<ProductEventData | undefined>(undefined);

  useEffect(() => {
    if (cart && search.searchResult?.products?.length) {
      setPreviews(search.searchResult.products?.map((p: PricedProduct) => transformProductPreview(p, cart.region)));
    } else {
      setPreviews(undefined);
    }
  }, [cart, search.searchResult]);

  useEffect(() => {
    if (gtmEventSent) {
      return;
    }

    if (previews?.length) {
      const event = productListViewEvent({
        itemListId: "searchSuggestions",
        itemListName: `Suchvorschläge ${searchValue}`,
        items: previews,
        eventBeforeSend: gtmEventSent,
      });

      if (setGtmEventSent) {
        setGtmEventSent(event);
      }
    }
  }, [gtmEventSent, previews, setGtmEventSent]);

  const onProductClick = useCallback(
    (product: ProductPreviewType, position: number) => {
      // track product click
      if (cart) {
        medusaRequest("POST", "/event", {
          body: {
            cartId: cart.id,
            event: {
              type: "searchResultClick",
              product: {
                productId: product.id,
                variantId: product.variants[0].id,
              },
              query: search.searchQuery,
              position,
              page: search.searchResult.pagination?.currentPage ?? 1,
              pageSize: search.searchResult.pagination?.resultsPerPage ?? 40,
            },
          },
        }).then(
          // ignore responses from tracking
          () => {},
          () => {},
        );
      }

      productCardClickEvent({
        itemListId: `suggest_${search.searchQuery}`,
        itemListName: `Suchvorschläge ${search.searchQuery}`,
        items: [product],
        index: position,
      });
      close();
    },
    [cart, close, search],
  );

  const searchEmpty = searchValue.length < 3;
  const searchProductsClass = "xl:col-span-3 col-span-6 sm:p-3 sm:col-span-4";

  return (
    <div className="max-w-[1620px] m-auto pl-5 sm:px-none md:px-7 md:pl-12 pr-5 md:pr-12 pt-8 sm:pt-0 md:pt-7 p-10">
      <p
        className={clsx("text-lg sm:text-xl font-semibold w-4/5 sm:w-full lg:w-9/12 hyphens-manual leading-snug sm:leading-normal", {
          invisible: searchValue.length < 3,
        })}
      >
        Alle Ergebnisse für &bdquo;{searchValue}&quot; anzeigen <ArrowDropRightIcon className="ml-3 w-6 inline-block align-middle" />
      </p>

      <div className="grid grid-cols-7">
        <div className="col-span-7 sm:col-span-2 w-full lg:w-9/12">
          <TermSuggests query={search.searchQuery} onClick={(term) => onSearch(term, search.searchQuery)} />
          <LastSearch onClick={(term) => onSearch(term)} />
        </div>
        <div className="col-span-7 sm:col-span-5">
          <div className="mt-14">
            <p className="uppercase text-2xl sm:text-3xl mt-4 sm:mt-0 mb-10">{searchEmpty ? "Beliebte Artikel" : "Vorgeschlagene Artikel"}</p>
            <div className={"grid gap-6 grid-cols-12"}>
              {search.isLoading ? (
                <>
                  <div className={searchProductsClass}>
                    <ProductCardSkeleton />
                  </div>
                  <div className={searchProductsClass}>
                    <ProductCardSkeleton />
                  </div>
                  <div className={"xl:col-span-3 col-span-6 hidden sm:p-3 sm:col-span-4 sm:block"}>
                    <ProductCardSkeleton />
                  </div>
                  <div className={"xl:col-span-3 col-span-6 hidden sm:p-3 sm:col-span-4 xl:block"}>
                    <ProductCardSkeleton />
                  </div>
                </>
              ) : null}
              {!search.isLoading
                ? previews?.map((product: ProductPreviewType, position) => {
                    return (
                      <div className={searchProductsClass} key={product.id}>
                        <ProductCard product={product} onClick={() => onProductClick(product, position + 1)} isProductList={true} gridCols={4} />
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
