"use client";

import clsx from "clsx";
import Link from "next/link";
import React, { useState } from "react";

import { Collapse, SubCategory } from "@/components/Form/Button/Collapse";
import { ArrowDropDownIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropDownIcon";
import { ArrowDropUpIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropUpIcon";
import { PRIMARY_CATEGORIES } from "@/components/Menu/CategoryBadges";
import { Flyout } from "@/components/Modal/Flyout";
import { SidebarSkeleton } from "@/components/Skeleton/Sidebar";
import { MenuLink } from "@/data/Menu";
import {
  SidebarDataCategoryInterface,
  SidebarDataInterface,
  SidebarDataLinkInterface,
  SidebarDataSubCategoryInterface,
} from "@/interfaces/Sidebar/SidebarInterface";
import { SIDEBAR_MODAL, useSideModal } from "@/lib/contexts/sidemodal-context";

interface StrapiItemAttributesInterface {
  attributes: {
    title: string;
    url?: string;
    children?: StrapiItemAttributesChildInterface;
  };
}

interface StrapiItemAttributesChildInterface extends StrapiItemAttributesInterface {
  data?: StrapiItemAttributesInterface[];
}

const Arrow = ({ collapsed }: { collapsed: boolean }) => {
  return !collapsed ? <ArrowDropUpIcon width={20} className="float-right mt-1" /> : <ArrowDropDownIcon width={20} className="float-right mt-1" />;
};

export const Sidebar = ({ className, links: menuLinks }: { className?: string; links: MenuLink[] }) => {
  const { modal, close } = useSideModal();
  const [selectedCollapse, setSelectedCollapse] = useState<SidebarDataInterface | null>(null);
  const [selectedSubCollapse, setSelectedSubCollapse] = useState<SidebarDataCategoryInterface | SidebarDataInterface | null>(null);

  const renderTitle = (
    collapse: {
      categories?: SidebarDataCategoryInterface[];
      subCategories?: SidebarDataSubCategoryInterface[];
      links?: SidebarDataLinkInterface[];
    } & (SidebarDataCategoryInterface | SidebarDataInterface),
  ) => {
    return (
      <>
        <span className={"tracking-wide font-semibold text-md"}>
          <span
            className={clsx("mr-1", {
              "text-ch21-color": PRIMARY_CATEGORIES.includes(collapse.name.toUpperCase()),
            })}
          >
            {collapse.name}
          </span>
        </span>
        {!collapse?.categories?.length && !collapse?.subCategories?.length && !collapse?.links?.length ? null : (
          <Arrow collapsed={!(selectedCollapse === collapse || selectedSubCollapse === collapse)} />
        )}
      </>
    );
  };

  const renderSidebarCategories = (collapse: SidebarDataInterface) => {
    if (!collapse?.links?.length) {
      return null;
    }
    return (
      <>
        {collapse?.links?.map((link: SidebarDataLinkInterface, key: number) => {
          return (
            <>
              {
                <Link
                  href={link.href || ""}
                  key={`sidebarCollapseLink-${key}`}
                  className={clsx("block py-2 px-4 text-md bg-white hover:category-bg-gray", { underline: link.underline })}
                  onClick={close}
                  {...(process.env.NODE_ENV !== "production" && {
                    "data-testid": `link${key}`,
                  })}
                >
                  {link.name}
                </Link>
              }
            </>
          );
        })}
      </>
    );
  };

  const renderSidebarSubCategories = (collapse: SidebarDataInterface) => {
    if (!collapse?.categories?.length) {
      return null;
    }
    return (
      <>
        {collapse?.categories?.map((category: SidebarDataCategoryInterface, key: number) => {
          if (!category?.subCategories?.length) {
            return (
              <Link
                href={category.href ?? ""}
                key={key}
                className={`block py-2 pl-8 pr-4 text-md bg-white hover:category-bg-gray font-semibold`}
                onClick={close}
                {...(process.env.NODE_ENV !== "production" && {
                  "data-testid": `link${key}`,
                })}
              >
                {category.name}
              </Link>
            );
          }

          return (
            <Collapse
              key={key}
              title={renderTitle(category)}
              customStyle={"bg-white hover:category-bg-gray pl-8"}
              customContentStyle={"bg-white"}
              onClick={() => (selectedSubCollapse === category ? setSelectedSubCollapse(null) : setSelectedSubCollapse(category))}
              enabled={selectedSubCollapse === category}
              id={"sidebarCategories"}
              {...(process.env.NODE_ENV !== "production" && {
                "data-testid": `categories${key}`,
              })}
            >
              <div className="m-auto">
                {category?.subCategories.map((subCategory: SidebarDataSubCategoryInterface, subKey) => (
                  <SubCategory
                    key={`subCategory-${subKey}`}
                    subCategory={subCategory}
                    link={subCategory.href ?? `/c/${subCategory.name}`}
                    onClick={close}
                    customStyle={`font-semibold ${subCategory?.underline && "underline"} ml-8`}
                  />
                ))}
                <Link
                  href={category.href ?? ""}
                  key={`subLink-${key}`}
                  className={`block p-2 text-md bg-white hover:category-bg-gray font-semibold underline`}
                  onClick={close}
                  data-testid={`link${key}`}
                >
                  <span className={"ml-8"}>{category.name} entdecken</span>
                </Link>
              </div>
            </Collapse>
          );
        })}
        <Link
          href={collapse.href ?? ""}
          key={collapse.categories.length + 1}
          className={`block p-2 pl-5 text-md bg-white hover:category-bg-gray font-semibold underline`}
          onClick={close}
          data-testid={`link${collapse.categories.length + 1}`}
        >
          {collapse.name} entdecken
        </Link>
        {collapse.landingpage && (
          <Link
            href={`${collapse.href}-Artikel`}
            key={collapse.categories.length + 2}
            className={`block p-2 pl-5 text-md bg-white hover:category-bg-gray font-semibold underline`}
            onClick={close}
            data-testid={`link${collapse.categories.length + 2}`}
          >
            Alle Artikel anzeigen
          </Link>
        )}
      </>
    );
  };

  return (
    <Flyout open={modal.name === SIDEBAR_MODAL && modal.isShow} onClose={close} className={className}>
      <div>
        {menuLinks.length <= 0 && <SidebarSkeleton />}
        {menuLinks.map((collapse: SidebarDataInterface, index: number) => {
          const isLink: boolean = !collapse?.categories?.length && !collapse?.links?.length;

          return (
            <Collapse
              key={index}
              title={renderTitle(collapse)}
              customStyle={"bg-white hover:category-bg-gray uppercase"}
              customContentStyle={"bg-white"}
              onClick={() => {
                if (isLink) {
                  close();
                  return;
                }

                selectedCollapse === collapse ? setSelectedCollapse(null) : setSelectedCollapse(collapse);
              }}
              enabled={selectedCollapse === collapse}
              link={isLink && collapse.href}
              id={"sidebarCollapse"}
              testId={process.env.NODE_ENV !== "production" ? `collapse${index}` : undefined}
            >
              {renderSidebarSubCategories(collapse)}
              {renderSidebarCategories(collapse)}
            </Collapse>
          );
        })}
      </div>
    </Flyout>
  );
};
