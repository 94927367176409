"use client";

import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { search as executeSearch } from "@/actions/search";
import { SearchResult, SearchSuggest } from "@/lib/contexts/search-context";

import LinkButton from "../../modules/common/components/link-button";

interface SuggestItemProps {
  suggest: SearchSuggest;
  onClick: (term: string) => void;
}

const SuggestItem = ({ suggest, onClick }: SuggestItemProps) => {
  return (
    <div className="mb-3" key={`search-${encodeURI(suggest.term)}`}>
      <LinkButton onClick={() => onClick(suggest.term)}>
        <div className="flex flex-row text-left">
          {suggest.term}
          {suggest.hits > 0 ? ` (${suggest.hits})` : null}
        </div>
      </LinkButton>
    </div>
  );
};

export const TermSuggests = ({ query, onClick }: { query: string; onClick: (term: string) => void }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResult, setSearchResult] = useState<SearchResult | null>(null);

  useEffect(() => {
    if (!query) {
      return;
    }

    setLoading(true);
    executeSearch({ query, type: "suggest" }).then((res) => {
      setSearchResult(res.body);
      setLoading(false);
    });
  }, [query]);

  if (loading) {
    return (
      <div className="mt-8 sm:mt-7 sm:pb-10 leading-[1.75]">
        <div className="mt-5">
          <div className="mb-3">
            <Skeleton />
          </div>
          <div className="mb-3">
            <Skeleton />
          </div>
          <div className="mb-3">
            <Skeleton />
          </div>
          <div className="mb-3">
            <Skeleton />
          </div>
          <div className="mb-3">
            <Skeleton />
          </div>
        </div>
      </div>
    );
  }

  if (!searchResult?.suggestions?.length) {
    return null;
  }

  return (
    <div className="mt-8 sm:mt-7 sm:pb-10 leading-[1.75]">
      <div className="mt-5">{searchResult.suggestions?.map((suggest) => <SuggestItem key={suggest.term} suggest={suggest} onClick={onClick} />)}</div>
    </div>
  );
};
