"use client";

import React from "react";

import { PasswordResetRequest } from "@/components/Form/Authentication/PasswordResetRequest";
import { Flyout } from "@/components/Modal/Flyout";
import { PASSWORD_RESET_MODAL, useSideModal } from "@/lib/contexts/sidemodal-context";

export const PasswordResetModal = () => {
  const { modal, setModal } = useSideModal();

  const onClose = () => setModal({ name: PASSWORD_RESET_MODAL, isShow: false });
  const isOpen = modal.name === PASSWORD_RESET_MODAL && modal.isShow;
  return (
    <Flyout open={isOpen} onClose={onClose} right={true}>
      <div className="mt-2">
        <div className="text-3xl text-center uppercase">Passwort zurücksetzen</div>
        <hr className="mt-7 mb-14 h-0.5 border-t-0 bg-black opacity-100" />

        {isOpen && <PasswordResetRequest />}
      </div>
    </Flyout>
  );
};
