import { EyeSlashIcon } from "@heroicons/react/24/solid";
import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { Input } from "@/components/Form/Input/Input";
import { ToggleInput } from "@/components/Form/Input/ToggleInput";
import Spinner from "@/components/Icon/spinner";
import { medusaClient } from "@/lib/config";
import { useAccount } from "@/lib/contexts/account-context";
import { useLoadingRouter } from "@/lib/contexts/loading-context";
import { PASSWORD_RESET_MODAL, useSideModal } from "@/lib/contexts/sidemodal-context";
import Button from "@/modules/common/components/button";

interface SignInCredentials extends FieldValues {
  email: string;
  password: string;
  remember_me: boolean;
}

export const LoginForm = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { refetchCustomer } = useAccount();
  const [authError, setAuthError] = useState<string | undefined>(undefined);
  const [showPassword, setShowPassword] = useState(false);
  const { setModal } = useSideModal();

  const router = useLoadingRouter();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<SignInCredentials>();

  const onSubmit = handleSubmit(async (credentials) => {
    await medusaClient.auth
      .authenticate(credentials)
      .then(() => {
        refetchCustomer();
        router.push("/account");
        onSuccess && onSuccess();
      })
      .catch(handleError);
  });

  const handleError = (_e: Error) => {
    setAuthError("An error occured. Please try again.");
  };

  return (
    <>
      <p className="font-semibold mb-3">Ich bin bereits Kunde</p>

      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <Input className="w-full" label="E-Mail" type="email" autoComplete={"username"} {...register("email")} />
        </div>

        <div className="mb-3 relative">
          <Input className="w-full" label="Passwort" type={showPassword ? "text" : "password"} autoComplete={"current-password"} {...register("password")} />
          <span onClick={() => setShowPassword(!showPassword)} className="cursor-pointer absolute right-5 top-3">
            <EyeSlashIcon className="h-5" />
          </span>
        </div>

        <Button className="p-5 font-light text-lg text-center w-full" type={"submit"} variant={"dark"}>
          Anmelden
        </Button>

        <div className="grid grid-cols-12">
          <div className="col-span-12 lg:col-span-6 mt-4">
            <ToggleInput label="Angemeldet bleiben" {...register("remember_me")} />
          </div>
          <div className="col-span-12 lg:col-span-6 mt-4 text-end">
            <span className="underline font-bold cursor-pointer" onClick={() => setModal({ name: PASSWORD_RESET_MODAL, isShow: true })}>
              Passwort vergessen?
            </span>
          </div>
        </div>

        {isSubmitting && (
          <div className="z-10 fixed inset-0 bg-white bg-opacity-50 flex items-center justify-center right-0 left-14 w-full">
            <Spinner size={24} />
          </div>
        )}

        {authError && (
          <div>
            <span className="text-rose-500 w-full text-small-regular">Benutzername und/oder Passwort sind inkorrekt.</span>
          </div>
        )}
      </form>
    </>
  );
};
