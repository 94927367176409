import React from "react";

interface InputInterface extends Omit<React.HTMLProps<HTMLInputElement>, "placeholder"> {
  label: string;
  name: string;
  errorMessage?: string;
  className?: string;
  onClick?: () => void;
}

export const ToggleInput: React.ForwardRefExoticComponent<React.PropsWithoutRef<InputInterface> & React.RefAttributes<HTMLInputElement>> = React.forwardRef(
  ({ onClick, label, name, ...props }: InputInterface, ref) => {
    return (
      <label className="relative inline-flex items-center mb-5 cursor-pointer">
        <input type="checkbox" value="" name={name} className="sr-only peer" onClick={onClick} ref={ref} {...props} />
        <div className="after:bg-black bg-white peer-checked:bg-pink-600 peer-checked:after:bg-white peer-checked:border-none w-8 h-5 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:absolute after:top-[5px] after:start-[5px] after:border-none after:border after:rounded-full after:transition-all border-2 border-black peer-focus:outline-none after:w-2.5 after:h-2.5"></div>
        <span className="ms-3 text-sm font-semibold text-black peer-checked:text-pink-600">{label}</span>
      </label>
    );
  },
);

ToggleInput.displayName = "ToggleInput";
