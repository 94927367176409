import { StorePostCustomersReq } from "@medusajs/medusa";
import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import { Input } from "@/components/Form/Input/Input";
import Spinner from "@/components/Icon/spinner";
import { medusaClient } from "@/lib/config";
import { useAccount } from "@/lib/contexts/account-context";
import { useLoadingRouter } from "@/lib/contexts/loading-context";
import Button from "@/modules/common/components/button";

interface RegisterCredentials extends FieldValues {
  email: string;
  password: string;
}

export const RegistrationForm = ({ title, button, onSuccess, email }: { title?: string; button?: React.ReactNode; onSuccess?: () => void; email?: string }) => {
  const { refetchCustomer } = useAccount();
  const [authError, setAuthError] = useState<string | undefined>(undefined);
  const router = useLoadingRouter();

  const handleError = (_e: Error) => {
    setAuthError("An error occured. Please try again.");
  };

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<RegisterCredentials>({ defaultValues: { email } });

  const onSubmit = handleSubmit(async (credentials) => {
    await medusaClient.customers
      .create(credentials as StorePostCustomersReq)
      .then(() => {
        refetchCustomer();
        router.push("/account");
        onSuccess && onSuccess();
      })
      .catch(handleError);
  });

  return (
    <>
      {title && <h1 className="text-2xl font-bold mb-3">{title}</h1>}
      <p className="font-semibold mb-3">
        Wenn Sie in unserem Shop ein Kundenkonto einrichten, werden Sie schneller durch den Bestellvorgang geführt, können mehrere Versandadressen speichern,
        Ihren bisherigen Bestellablauf verfolgen und vieles mehr.
      </p>

      <form onSubmit={onSubmit}>
        <div className="mb-3">
          <Input className="w-full" label="E-Mail" type="email" autoComplete={"email"} {...register("email")} />
        </div>

        <div className="mb-3">
          <Input className="w-full" label="Passwort" type="password" autoComplete={"new-password"} {...register("password")} />
        </div>

        <div className="mb-3">
          <Input className="w-full" label="Passwort wiederholen" type="password" autoComplete={"new-password"} name={"confirm_password"} />
        </div>

        {button ? (
          button
        ) : (
          <Button className="p-5 font-light text-lg text-center w-full" variant={"dark"} type={"submit"}>
            Registrieren
          </Button>
        )}

        {isSubmitting && (
          <div className="z-10 fixed inset-0 bg-white bg-opacity-50 flex items-center justify-center right-0 left-14 w-full">
            <Spinner size={24} />
          </div>
        )}

        {authError && (
          <div>
            <span className="text-rose-500 w-full text-small-regular">Bei der Registrierung ist ein Fehler aufgetreten.</span>
          </div>
        )}
      </form>
    </>
  );
};
