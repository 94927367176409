"use client";

import React from "react";

import { LoginForm } from "@/components/Form/Authentication/Login";
import { RegistrationForm } from "@/components/Form/Authentication/Registration";
import { Flyout } from "@/components/Modal/Flyout";
import { AUTHENTICATION_MODAL, useHashToModal, useSideModal } from "@/lib/contexts/sidemodal-context";

export const AuthenticationModal = () => {
  const { modal, setModal } = useSideModal();

  useHashToModal("login", AUTHENTICATION_MODAL);

  const onClose = () => setModal({ name: AUTHENTICATION_MODAL, isShow: false });
  return (
    <div id="login">
      <Flyout open={modal.name === AUTHENTICATION_MODAL && modal.isShow} onClose={onClose} right={true}>
        <div className="mt-2">
          <div className="text-3xl text-center uppercase">Anmelden</div>
          <hr className="mt-7 mb-14 h-0.5 border-t-0 bg-black opacity-100" />

          <LoginForm onSuccess={onClose} />

          <div className="mt-10 mb-5">
            <div className="text-3xl text-center uppercase">Oder Registrieren</div>
            <hr className="mt-7 mb-14 h-0.5 border-t-0 bg-black opacity-100" />

            <RegistrationForm onSuccess={onClose} />
          </div>
        </div>
      </Flyout>
    </div>
  );
};
