"use client";

import { ChevronLeftIcon } from "@heroicons/react/20/solid";
import { Customer } from "@medusajs/medusa";
import clsx from "clsx";
import { useCart } from "medusa-react";
import Image from "next/image";
import Link from "next/link";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { search as executeSearch, SearchParams } from "@/actions/search";
import { Bars3Icon } from "@/components/Icon/Bars3Icon";
import { CartIcon } from "@/components/Icon/CartIcon";
import { HeartLineIcon } from "@/components/Icon/Heart/HeartLineIcon";
import { PaybackIcon } from "@/components/Icon/Provider/PaybackIcon";
import { SearchIcon } from "@/components/Icon/SearchIcon";
import { UserCircleIcon } from "@/components/Icon/User/UserCircleIcon";
import SearchInput from "@/components/Menu/SearchInput";
import { SearchComponent } from "@/components/Search";
import { SEARCH_STORAGE } from "@/components/Search/last-search";
import { useDebounce } from "@/hook/debounce";
import { useAccount } from "@/lib/contexts/account-context";
import { useLayout } from "@/lib/contexts/layout-context";
import { useSearch } from "@/lib/contexts/search-context";
import { AUTHENTICATION_MODAL, CART_MODAL, SEARCH_MODAL, SIDEBAR_MODAL, useSideModal } from "@/lib/contexts/sidemodal-context";
import { useStorage } from "@/lib/hooks/use-storage";
import LinkButton from "@/modules/common/components/link-button";

const AccountLink = ({ customer, openModal }: { customer: Omit<Customer, "password_hash"> | undefined; openModal: () => void }) => {
  return !customer ? (
    <LinkButton onClick={openModal} className={"flex items-center"} aria-label="Anmelden">
      <UserCircleIcon className="w-6 lg:w-7 float-left mr-1" />
      <span className="text-xs ml-1 font-bold hidden 2xl:inline-block">Anmelden</span>
    </LinkButton>
  ) : (
    <Link href={"/account"}>
      <UserCircleIcon className="w-6 lg:w-7 float-left mr-1" />
      <span className="text-xs ml-1 font-bold hidden 2xl:inline-block">Konto</span>
    </Link>
  );
};

const Logo = ({ className, onClick }: { className?: string; onClick?: () => void }) => (
  <Link href="/" onClick={onClick}>
    <Image src="/img/channel21_logo.svg" className={className} alt="CHANNEL21" width={325} height={45} priority={true} />
  </Link>
);

const RenderCategoryBadges = ({ show = true, children }: React.PropsWithChildren<{ show?: boolean }>) => {
  const el = useRef<HTMLDivElement | null>(null);
  const {
    modal: { name: modalName, isShow: isModalVisible },
  } = useSideModal();

  useEffect(() => {
    const header = document.querySelector<HTMLHeadingElement>("header.sticky");
    if (!header) {
      return () => {};
    }
    if (el.current) {
      header.style.top = `${el.current.clientHeight * -1}px`;
    }
    return () => {
      header.style.top = "";
    };
  }, [show, isModalVisible]);

  if (modalName === SEARCH_MODAL) {
    if (isModalVisible) {
      return null;
    }
  }

  if (show) {
    return (
      <div className="xl:pt-3" ref={el}>
        {children}
      </div>
    );
  }

  return null;
};

const HeaderComponent = ({ onSearch, initQuery }: { onSearch?: (query: string) => void; initQuery?: string }) => {
  const { cart } = useCart();
  const storage = useStorage(SEARCH_STORAGE);
  const [search, setSearch] = useState<string>(initQuery ?? "");
  const [searchQuery, setSearchQuery] = useState<string>(initQuery ?? "");
  const { setSearchResult, setSearchQuery: setContextSearchQuery, setLoading } = useSearch();
  const {
    setModal,
    modal: { name: modalName, isShow: isModalVisible },
    close: closeModal,
  } = useSideModal();

  useEffect(() => {
    if (searchQuery.length < 3 || !cart) {
      return;
    }

    const searchResult = async () => {
      setContextSearchQuery(searchQuery);
      setLoading(true);
      executeSearch({ query: searchQuery, type: "search", additionalOptions: { cartId: cart.id, expand: "variants,variants.options" } }).then((res) => {
        setSearchResult(res.body);
        setLoading(false);
      });
    };
    searchResult().then((r) => r);
  }, [setSearchResult, searchQuery, setContextSearchQuery, cart, setLoading]);

  const debouncedSearchQuery = useDebounce(() => {
    setSearchQuery(search);
    onSearch && onSearch(search);
  }, 500);

  const debouncedSearch = useCallback(
    (valueSearch: string) => {
      setSearch(valueSearch);
      if (valueSearch.length < 3) {
        return;
      }
      debouncedSearchQuery();
    },
    [debouncedSearchQuery],
  );

  if (modalName === SEARCH_MODAL && isModalVisible) {
    return (
      <>
        <div className="flex flex-nowrap pl-0 md:p-3 lg:pl-10 text-xs self-center" onClick={closeModal}>
          <ChevronLeftIcon className="h-6 cursor-pointer" />
          <span className="font-semibold cursor-pointer pt-[2px] sm:pt-0 pr-5 whitespace-nowrap self-center">Zurück</span>
        </div>
        <SearchInput
          placeholder=""
          onChange={(event) => debouncedSearch(event.target.value)}
          onKeyDown={(event) => {
            if (event.code === "Enter") {
              storage.add(event.currentTarget.value, event.currentTarget.value, { onDuplicateName: "readd", cappedSize: 5 });
            }
          }}
          onReset={() => debouncedSearch("")}
          value={search}
        />
        <div className="hidden sm:block">
          <Logo className="h-[20px] 2xl:h-[45px] lg:w-[325px] w-[8.5rem] sm:mx-auto max-w-[none]" onClick={closeModal} />
        </div>
      </>
    );
  }

  return (
    <div className={"flex basis-14 sm:basis-1/3"}>
      <span
        onClick={() => (modalName === SIDEBAR_MODAL ? closeModal() : setModal({ name: SIDEBAR_MODAL, isShow: true }))}
        {...(process.env.NODE_ENV !== "production" && {
          "data-testid": "headerMenuToggle",
        })}
      >
        <Bars3Icon className="w-6 lg:w-7 cursor-pointer lg:mt-0 mr-5" />
      </span>
      <span
        onClick={() => setModal({ name: SEARCH_MODAL, isShow: true })}
        {...(process.env.NODE_ENV !== "production" && {
          "data-testid": "headerSearchToggle",
        })}
      >
        <SearchIcon className="w-6 lg:w-7 lg:ml-5 cursor-pointer hidden md:inline-block align-top" />
      </span>
    </div>
  );
};

const CartItemsBadge = () => {
  const { cart } = useCart();

  if (!cart?.items.length) {
    return null;
  }

  return (
    <div className="absolute bottom-0 right-1">
      <span className="bg-primary text-white text-[.5rem] font-medium p-0.5 px-1 rounded-full m-auto relative top-1.5 inline-block text-center leading-[10px] min-w-[14px]">
        {cart?.items.length}
      </span>
    </div>
  );
};

export default function Menu({ categoryBadges }: { categoryBadges?: React.ReactNode }) {
  const { cart } = useCart();
  const { showCategories } = useLayout();
  const {
    setModal,
    modal: { name, isShow },
    close,
  } = useSideModal();
  const { customer } = useAccount();
  const { setSearchResult, searchQuery, setSearchQuery: setContextSearchQuery, setLoading } = useSearch();
  const [search, setSearch] = useState<string>(searchQuery);

  useEffect(() => {
    setSearch(searchQuery);
  }, [searchQuery]);

  const handleShowCart = () => {
    setModal({
      name: CART_MODAL,
      isShow: true,
    });
  };

  const updateSuggest = useCallback(
    async (query: string, originalQuery?: string) => {
      setSearch(query);
      setContextSearchQuery(query);

      const additionalOptions: SearchParams["additionalOptions"] = {};

      if (cart) {
        additionalOptions.cartId = cart.id;
      }

      if (originalQuery) {
        additionalOptions.userInput = originalQuery;
      }

      setLoading(true);
      executeSearch({ query, type: "search", additionalOptions }).then((res) => {
        setSearchResult(res.body);
        setLoading(false);
      });
    },
    [cart, setContextSearchQuery, setSearchResult, setLoading],
  );

  const isSearchActive = name === SEARCH_MODAL && isShow;

  useEffect(() => {
    // Muss als Timeout gesetzt werden damit styling aus sidemodal-context überschrieben wird
    setTimeout(() => (document.body.style.overflowY = isSearchActive ? "hidden" : "auto"), 50);
  }, [isSearchActive]);

  return (
    <header
      className={clsx("bg-white shadow-md w-full sticky z-50 top-0", {
        "h-screen overflow-y-auto": isSearchActive,
      })}
    >
      <div className="shadow-md">
        <div className="max-w-[1620px] mx-auto px-4 sm:px-6 lg:px-12 overflow-hidden md:overflow-visible">
          <div className="bg-white sticky top-0 z-10 flex flex-nowrap flex-row items-center py-4 sm:py-3 lg:py-5 before:content-[''] before:absolute before:w-[30px] before:h-[30px] lg:before:h-[50px] before:bg-white before:left-[-30px] after:content-[''] after:absolute after:w-[30px] after:h-[30px] lg:after:h-[50px] after:bg-white after:right-[-30px]">
            <HeaderComponent initQuery={searchQuery} onSearch={(searchString: string) => setSearch(searchString)} />

            <div className={isSearchActive ? "hidden" : "basis-1/3 sm:text-center mt-[2px] lg:mt-0"}>
              <Logo className="h-[20px] 2xl:h-[45px] lg:w-[325px] w-[8.5rem] sm:mx-auto max-w-[none]" onClick={close} />
            </div>

            <div className={`basis-1/3 ${isSearchActive ? "hidden" : "flex justify-end items-center ml-auto sm:pr-1 lg:pr-0"}`}>
              <div className="mr-0 md:mr-4 hidden sm:flex items-center">
                <Link href="/payback" className="leading-[1]">
                  <PaybackIcon className="inline-block mr-1.5 w-5 h-5 lg:w-6 lg:h-6" width="1.75rem" height="1.75rem" />
                  <span className="ml-1 uppercase text-xs text-payback font-bold hidden 2xl:inline-block">Payback</span>
                </Link>
              </div>
              <div className="mr-0 md:mr-4 inline-block md:hidden" onClick={() => setModal({ name: SEARCH_MODAL, isShow: true })}>
                <SearchIcon className="w-6 lg:w-7 cursor-pointer mr-1" />
              </div>
              <div className="mr-0 md:mr-4 block md:hidden">
                <Link href={"/account/wishlist"} aria-label="Wunschliste">
                  <HeartLineIcon className="w-6 lg:w-7 float-left mr-1" />
                  <span className="ml-1 text-xs font-bold hidden 2xl:inline-block">Wunschliste</span>
                </Link>
              </div>
              <div className="mr-0 md:mr-4 block md:hidden">
                <AccountLink openModal={() => setModal({ name: AUTHENTICATION_MODAL, isShow: true })} customer={customer} />
              </div>
              <div className="mr-0 md:mr-4 hidden md:block">
                <AccountLink openModal={() => setModal({ name: AUTHENTICATION_MODAL, isShow: true })} customer={customer} />
              </div>
              <div className="mr-0 md:mr-4 hidden md:block">
                <Link href={"/account/wishlist"} aria-label="Wunschliste">
                  <HeartLineIcon className="w-6 lg:w-7 float-left mr-1" />
                  <span className="ml-1 text-xs font-bold hidden 2xl:inline-block">Wunschliste</span>
                </Link>
              </div>
              <div className="mr-0">
                <div className="cursor-pointer flex items-center" onClick={() => handleShowCart()}>
                  <div className="flex relative">
                    <CartIcon className="w-6 lg:w-7 mr-1" />
                    <CartItemsBadge />
                  </div>
                  <span className="ml-1 text-xs font-bold hidden 2xl:inline-block">Warenkorb</span>
                </div>
              </div>
            </div>
          </div>
          <RenderCategoryBadges show={showCategories}>{categoryBadges}</RenderCategoryBadges>
        </div>
      </div>
      {isSearchActive && (
        <div className="relative">
          <SearchComponent searchValue={search} onSearch={(term, originalQuery) => updateSuggest(term, originalQuery)} />
        </div>
      )}
    </header>
  );
}
