"use client";

import clsx from "clsx";
import Link from "next/link";
import React, { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";

import { Badge } from "@/components/Badge";
import { ArrowDropLeftIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropLeftIcon";
import { ArrowDropRightIcon } from "@/components/Icon/Arrow/ArrowDrop/ArrowDropRightIcon";
import { MenuLink } from "@/data/Menu";
import { isParentMenuLink, LeafMenuLink } from "@/data/Menu/fetch-strapi-menu";

export const CategoryBadgesSkeleton = () => {
  return (
    <div className="overflow-x-auto flex pb-1 sm:pb-2 xl:pb-3" style={{ scrollBehavior: "smooth", scrollbarWidth: "none" }}>
      <div className="pr-3 uppercase">
        {[1, 2, 3, 4].map((index) => (
          <Skeleton key={index} width={65} height={20} containerClassName={"inline-block pr-3"} className={"xl:!w-[100px]"} />
        ))}
      </div>
    </div>
  );
};

const shouldScrollButtonsBeShown = (
  setShowLeftButton: (value: ((prevState: boolean) => boolean) | boolean) => void,
  el: HTMLDivElement,
  setShowRightButton: (value: ((prevState: boolean) => boolean) | boolean) => void,
) => {
  const position = el.scrollLeft;
  const client = el.clientWidth;
  const scroll = el.scrollWidth;

  setShowLeftButton(position >= 10);
  setShowRightButton(position + client <= scroll - 10);
};

export const PRIMARY_CATEGORIES = ["LIVE"];

export const CategoryBadges = ({ links }: { links: MenuLink[] }) => {
  const [isShowLeftButton, setShowLeftButton] = useState<boolean>(false);
  const [isShowRightButton, setShowRightButton] = useState<boolean>(false);
  const categoryBades = useRef<HTMLDivElement>(null);
  let mouseDown = false;
  let startX: number, scrollLeft: number;

  const startDragging = (e: MouseEvent) => {
    mouseDown = true;
    startX = e.pageX - categoryBades.current!.offsetLeft;
    scrollLeft = categoryBades.current!.scrollLeft;
  };

  const stopDragging = () => {
    mouseDown = false;
  };

  const move = (e: MouseEvent) => {
    e.preventDefault();
    if (!mouseDown) {
      return;
    }
    const x = e.pageX - categoryBades.current!.offsetLeft;
    const scroll = x - startX;
    categoryBades.current!.scrollLeft = scrollLeft - scroll;
  };

  const scrollX = (cont: HTMLElement, left: boolean) => {
    let scrollWidthEl = 200;
    const elements = cont.getElementsByClassName("c21__scroll__item");

    if (elements.length >= 1) {
      scrollWidthEl = elements[elements.length - 1].scrollWidth;
    }

    cont.scrollLeft += left ? scrollWidthEl : -scrollWidthEl;
  };

  const scrollInContainerX = (left: boolean) => {
    categoryBades.current && scrollX(categoryBades.current, left);
  };

  useEffect(() => {
    const categoryBadgesElement = categoryBades.current;
    categoryBadgesElement?.addEventListener("mousemove", move, false);
    categoryBadgesElement?.addEventListener("mousedown", startDragging, false);
    categoryBadgesElement?.addEventListener("mouseup", stopDragging, false);
    categoryBadgesElement?.addEventListener("mouseleave", stopDragging, false);

    return () => {
      categoryBadgesElement?.removeEventListener("mousemove", move, false);
      categoryBadgesElement?.removeEventListener("mousedown", startDragging, false);
      categoryBadgesElement?.removeEventListener("mouseup", stopDragging, false);
      categoryBadgesElement?.removeEventListener("mouseleave", stopDragging, false);
    };
  });

  useEffect(() => {
    const el = categoryBades.current;
    if (el) {
      // on Scroll Events
      const eventListener = () => {
        shouldScrollButtonsBeShown(setShowLeftButton, el, setShowRightButton);
      };

      // on Container Resize Events
      const handleResize = (entries: ResizeObserverEntry[]) => {
        for (const entry of entries) {
          if (entry.contentRect) {
            shouldScrollButtonsBeShown(setShowLeftButton, el, setShowRightButton);
          }
        }
      };
      const resizeObserver = new ResizeObserver(handleResize);

      eventListener();
      el.addEventListener("scroll", eventListener);
      resizeObserver.observe(el);

      return () => {
        el.removeEventListener("scroll", eventListener);
        resizeObserver.unobserve(el);
      };
    }
  });

  if (!links.length) {
    return null;
  }

  return (
    <div className="relative">
      <div
        className={clsx("absolute -left-5 top-0 cursor-pointer bg-gradient-to-l from-transparent to-white to-25%", {
          hidden: !isShowLeftButton,
          block: isShowLeftButton,
        })}
        onClick={() => scrollInContainerX(false)}
      >
        <ArrowDropLeftIcon width="25" height="25" className="relative" fill="#000" />
      </div>
      <div
        className="overflow-x-auto flex pb-1 sm:pb-2 xl:pb-3 scrolling-touch select-none "
        ref={categoryBades}
        style={{ scrollBehavior: "smooth", scrollbarWidth: "none" }}
      >
        {links
          .filter((link): link is LeafMenuLink => !isParentMenuLink(link))
          .map((link: LeafMenuLink, index) => (
            <div className="pr-3 uppercase" key={`categoryBadge${link.id ?? index}`}>
              <Link href={link.href ?? ""}>
                <Badge
                  name={link.name}
                  className={clsx({
                    "text-ch21-color": PRIMARY_CATEGORIES.includes(link.name.toUpperCase()),
                  })}
                />
              </Link>
            </div>
          ))}
      </div>
      <div
        className={clsx("absolute -right-5 top-0 cursor-pointer bg-gradient-to-r from-transparent to-white to-25%", {
          hidden: !isShowRightButton,
          block: isShowRightButton,
        })}
        onClick={() => scrollInContainerX(true)}
      >
        <ArrowDropRightIcon width="25" height="25" className="relative" fill="#000" />
      </div>
    </div>
  );
};
