import { IconInterface } from "@/components/Icon/IconInterface";

export const PaybackIcon = ({ className, width, height }: IconInterface) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width={width ?? "200.000000pt"}
    height={height ?? "200.000000pt"}
    viewBox="0 0 200.000000 200.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)" fill="#0046AA" stroke="none">
      <path
        d="M98 1971 c-27 -17 -57 -48 -72 -74 l-26 -44 0 -855 0 -855 29 -40
  c16 -23 46 -54 67 -69 l37 -29 857 -3 856 -2 47 30 c27 17 60 50 77 77 l30 47
  -2 856 -3 857 -29 37 c-15 21 -46 51 -69 67 l-40 29 -857 0 -856 0 -46 -29z
  m674 -333 c57 -24 132 -101 160 -166 29 -64 29 -170 1 -233 -26 -58 -84 -123
  -137 -154 -203 -116 -459 34 -459 270 0 83 23 142 79 204 94 105 227 134 356
  79z m690 6 c110 -36 188 -146 196 -275 4 -67 1 -83 -23 -138 -74 -163 -260
  -230 -421 -151 -188 92 -232 342 -85 489 93 93 203 118 333 75z m-716 -704
  c80 -28 137 -78 175 -156 79 -160 12 -345 -152 -419 -55 -24 -71 -27 -138 -23
  -174 11 -292 133 -294 305 -2 214 207 364 409 293z m732 -10 c68 -29 144 -112
  167 -181 23 -69 17 -178 -14 -238 -70 -137 -238 -205 -384 -155 -60 20 -141
  93 -173 155 -105 208 60 461 291 446 33 -3 84 -14 113 -27z"
      />
      <path
        d="M560 1587 c-49 -16 -133 -102 -148 -153 -29 -98 -8 -178 63 -249 102
  -102 238 -102 340 0 102 102 102 238 0 340 -70 71 -159 92 -255 62z"
      />
      <path
        d="M532 865 c-260 -132 -100 -533 180 -451 70 20 120 64 154 132 54 110
  14 244 -90 309 -40 24 -62 30 -120 33 -61 3 -78 0 -124 -23z"
      />
      <path
        d="M1245 869 c-88 -44 -135 -120 -135 -219 0 -142 100 -244 240 -244
  227 -1 334 274 164 424 -21 18 -52 39 -70 46 -49 21 -151 17 -199 -7z"
      />
    </g>
  </svg>
);
