import clsx from "clsx";
import React, { useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";

import Alert from "@/components/Alert";
import { Input } from "@/components/Form/Input/Input";
import Spinner from "@/components/Icon/spinner";
import { medusaClient } from "@/lib/config";
import Button from "@/modules/common/components/button";

interface PasswordResetRequestData extends FieldValues {
  email: string;
}

function ResponseMessage(props: { type: "success" | "error"; message: string }) {
  return (
    <Alert type={props.type} className="mb-3">
      {props.message}
    </Alert>
  );
}

export const PasswordResetRequest = () => {
  const [responseInfo, setResponseInfo] = useState<{ type: "success" | "error"; message: string } | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<PasswordResetRequestData>();

  const onSubmit = useMemo(
    () =>
      handleSubmit(async (credentials) => {
        if (isSubmitting) {
          return;
        }

        await medusaClient.customers
          .generatePasswordToken(credentials)
          .then(() => {
            setResponseInfo({ type: "success", message: "Es wurde eine E-Mail verschickt, mit welcher Sie Ihr Passwort zurücksetzen können." });
            reset();
          })
          .catch(handleError);
      }),
    [handleSubmit, isSubmitting, reset],
  );

  const handleError = () => {
    setResponseInfo({ type: "error", message: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut." });
  };

  return (
    <>
      <p className="font-semibold mb-3">
        Bitte geben Sie Ihre E-Mail-Adresse an.
        <br />
        Wir schicken Ihnen einen Link, mit dem Sie Ihr Passwort zurücksetzen können.
      </p>

      {responseInfo && <ResponseMessage type={responseInfo.type} message={responseInfo.message} />}

      {(!responseInfo || responseInfo.type !== "success") && (
        <form onSubmit={onSubmit}>
          <div className="mb-3">
            <Input
              disabled={isSubmitting}
              className={clsx("w-full", {
                "border-2 border-rose-600": errors.email,
              })}
              label="E-Mail"
              type="email"
              autoComplete={"username"}
              {...register("email", { required: true })}
            />
            {errors.email && <span className={"text-rose-600"}>Dies ist ein Pflichtfeld</span>}
          </div>

          <Button
            className="p-5 font-light text-lg text-center w-full flex items-center justify-center"
            type={"submit"}
            variant={"dark"}
            disabled={isSubmitting}
          >
            {isSubmitting && <Spinner size={24} className="mr-2" />}
            Passwort zurücksetzen
          </Button>
        </form>
      )}
    </>
  );
};
