import React from "react";
import Skeleton from "react-loading-skeleton";

export const SidebarSkeleton = () => {
  return (
    <>
      <Skeleton count={2} width={150} />
      <Skeleton count={3} width={125} style={{ marginLeft: "25px" }} />
      <Skeleton count={2} width={150} />
    </>
  );
};
